/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, ChangeEvent, useContext, useEffect } from 'react';
import { Modal, Typography, Button, Grid, DialogTitle, Dialog, DialogContent, IconButton, Snackbar, Alert } from '@mui/material';
import InventoryIcon from '@mui/icons-material/Inventory';
import { useTranslation } from 'react-i18next';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { UserContext } from '../../../components/shared/useUser';
import { exportFile } from '../../../utils';
import useLogError from '../../../hooks/useLogError';
import * as XLSX from 'xlsx';
import { X } from './../../../assets';
import { Loader } from '../../../components/ui/Loader';

interface CreateBulkManageProps {
  open: boolean;
  handleClose: () => void;
  onSuccess: (message: string) => void;
}

const CreateBulkManage: React.FC<CreateBulkManageProps> = ({ open, handleClose, onSuccess }) => {
  const { t } = useTranslation('bulk');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const { exportBulkRecipes, uploadBulkRecipes } = useNswagClient();
  const { selectedStore } = useContext(UserContext);
  const { logError } = useLogError();
  const [showUploadSuccessMessage, setShowUploadSuccessMessage] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const expectedColumns = ['Stores and Franchises', 'Country', 'Recipe SKU', 'Recipe Name', 'Category', 'Ingredient SKU', 'Ingredient Name', 'Weight (G)', 'Primary UOM Quantity'];

  useEffect(() => {
    if (!open) {
      setErrorMessage(null);
      setSuccessMessage(null);
      setSelectedFile(null);
    }
  }, [open]);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setErrorMessage(null);
    const file = event.target.files?.[0] ?? null;

    if (file) {
      const fileType = file.name.split('.').pop();
      if (fileType !== 'xlsx') {
        setErrorMessage(t('errorInvalidFile'));
        setSelectedFile(null);
        return;
      }

      try {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const headers = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0] as string[];

        const hasAllColumns = expectedColumns.every(col => headers.includes(col));
        if (!hasAllColumns) {
          setErrorMessage(t('errorInvalidTemplate'));
          setSelectedFile(null);
        } else {
          setSelectedFile(file);
          setSuccessMessage(t('fileUploadSuccess'));
        }
      } catch (error) {
        setErrorMessage(t('errorReadingFile'));
        setSelectedFile(null);
        logError(error);
      }
    }
  };

  const handleDownloadSample = (e: any) => {
    e.preventDefault();
    if (selectedStore?.storeNumber) {
      setIsExporting(true);
      exportBulkRecipes(selectedStore.storeNumber)
        .then((response) => {
          if (response) {
            exportFile(
              response.data,
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              `Recipes-Bulk-${selectedStore?.storeNumber}.xlsx`,
            );
          }
        })
        .catch(logError)
        .finally(() => {
          setIsExporting(false);
          handleClose();
        });
    }
  };

  const handleSave = async () => {
    if (!selectedFile) {
      setErrorMessage(t('errorInvalidFile'));
      return;
    }

    setIsSaving(true);
    setErrorMessage(null);

    try {
      const storeNumber = selectedStore?.storeNumber ?? '';
      await uploadBulkRecipes(storeNumber, selectedFile);
      setShowUploadSuccessMessage(true);
      setSelectedFile(null);
      onSuccess('Recipes updated successfully!');
    } catch (error) {
      logError(error);
      setErrorMessage(t('errorUploadingFile'));
    } finally {
      setIsSaving(false);
    }
  };


  const handleCloseSnackbar = () => {
    setShowUploadSuccessMessage(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Dialog open={open}
        onClose={handleClose}
        maxWidth='md'
        fullWidth>
        <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
          <InventoryIcon
            sx={{
              mr: 1,
              color: 'black',
              fontSize: '20px',
              width: '20px',
              height: '24px',
            }}
          />
          <Typography variant="textXL"
            sx={{ display: 'flex', flex: 1, justifyContent: 'left', alignItems: 'center' }}>
            {t('modalTitle')}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              p: 0,
            }}
          >
            <X />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography
            id="modal-description"
            sx={{
              textAlign: 'left',
              mb: 3,
              fontSize: { xs: '0.9rem', sm: '1rem' },
            }}
          >
            {t('modalDescription')}
          </Typography>
          <Grid item
            xs={12}
            sx={{ display: 'flex', justifyContent: 'left', columnGap: '10px' }}>
            <Button size='lg'
              variant='secondary'
              color="secondary"
              onClick={handleDownloadSample}
              disabled={isExporting}
            >
              {isExporting ? t('exporting') : t('download')}
            </Button>
            <Button size='lg'
              color="secondary"
              onClick={() => document.getElementById('fileInput')?.click()}
              sx={{ marginRight: '8px' }}
            >
              {t('upload')}
            </Button>
          </Grid>
          <input
            type="file"
            id="fileInput"
            accept=".xlsx"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          {errorMessage && (
            <Typography
              color="error"
              sx={{
                textAlign: 'center',
                mt: 2,
                fontSize: { xs: '0.8rem', sm: '1rem' },
              }}
            >
              {errorMessage}
            </Typography>
          )}
          {successMessage && (
            <Typography
              color="success"
              sx={{
                textAlign: 'center',
                mt: 2,
                fontSize: { xs: '0.8rem', sm: '1rem' },
              }}
            >
              {successMessage}
            </Typography>
          )}
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'right',
              columnGap: { xs: '8px', sm: '10px' },
            }}
          >
            <Button
              size="lg"
              variant="secondary"
              onClick={handleClose}
              sx={{
                marginTop: { xs: '8px', sm: '0' },
              }}
            >
              {t('cancel')}
            </Button>
            <Button
              size="lg"
              onClick={handleSave}
              disabled={!selectedFile || !!errorMessage || isSaving}
              sx={{ marginTop: { xs: '8px', sm: '0' } }}
            >
              {isSaving ? <Loader /> : t('save')}
            </Button>

          </Grid>

        </DialogContent>

        {/* Success Snackbar */}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={showUploadSuccessMessage}
          onClose={handleCloseSnackbar}
          autoHideDuration={5000}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            variant="filled"
            sx={{
              width: '100%',
            }}
          >
            {t('fileUploadSuccess')}
          </Alert>
        </Snackbar>
      </Dialog>
    </Modal>
  );
};

export default CreateBulkManage;
