import { Box,Grid, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { StockCheckUom, UpdateStockCheckUomsRequest } from '../../../app/services/api/generated';
import { useContext, useEffect, useState } from 'react';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../components/shared/useUser';
import { theme } from '../../../theme';
import useNswagClient from '../../../hooks/api/useNswagClient';
import InfoIcon from '@mui/icons-material/Info';

const CountingUOMBox = () => {
  const { t } = useTranslation('common');
  const { selectedStore, hasPermissionTo } = useContext(UserContext);
  const { getStockCheckUoms, updateStockCheckUom } = useNswagClient();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countingUoms, setCountingUoms] = useState<StockCheckUom[] | undefined>([]);
  const [maxOrderNumber, setMaxOrderNumber] = useState(8);
  const [countingUomsIsSaving, setCountingUomsIsSaving] = useState(false);

  const hasPermissionToWrite: boolean = hasPermissionTo(['StockCountUomsWrite']);


  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setIsLoading(true);
    getStockCheckUoms(selectedStore?.storeNumber ?? '').then((result) => {
      if (result?.data) {
        setCountingUoms(result?.data?.stockCheckUoms);
        setCountingUoms(reorderCountingUoms(result?.data?.stockCheckUoms ?? []));
        getMaxOrderNumber(result?.data?.stockCheckUoms ?? []);
      }
    })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => (setIsLoading(false)));
  };

  const updateStockCheckUoms = (orderCountingUoms : StockCheckUom[]) => {
    if (!orderCountingUoms) {
      return; 
    }
  
    const request: UpdateStockCheckUomsRequest = { stockCheckUoms: orderCountingUoms };
  
    updateStockCheckUom(request)
      .then(() => {
        setCountingUomsIsSaving(false);
        setCountingUoms(orderCountingUoms);
      })
      .catch(error => {
        console.error(error);
        setCountingUomsIsSaving(false);
      });
  };

  const getMaxOrderNumber = (countingUoms : StockCheckUom[]) => {
    setMaxOrderNumber(countingUoms.filter(uom => uom.visible).length);
  };


  const reorderCountingUoms = (uoms: StockCheckUom[]): StockCheckUom[] => {
    uoms.sort((a, b) => {
      const orderA = a.orderNumber ?? Number.MAX_SAFE_INTEGER;
      const orderB = b.orderNumber ?? Number.MAX_SAFE_INTEGER;
      return orderA <= orderB ? -1 : 1; 
    });

    uoms.forEach((item, index) => {
      item.orderNumber = index + 1; 
    });

    return uoms; 
  };
  
  
  const saveToggleButton = (row: StockCheckUom, checked: boolean) => {
    setCountingUomsIsSaving(true);
  
    const visibleUoms = countingUoms?.filter(uom => uom.id !== row.id && uom.visible) ?? [];
  
    const lastOrderNumber = visibleUoms.length > 0 ? visibleUoms[visibleUoms.length - 1].orderNumber ?? 0 : 0;
  
    row.orderNumber = lastOrderNumber + 1;
    row.visible = checked; 
  
    const sortedCountingUoms = reorderCountingUoms(countingUoms ?? []);
    getMaxOrderNumber(sortedCountingUoms ?? []);
    updateStockCheckUoms(sortedCountingUoms);
  };
  
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, row: StockCheckUom) => {
    let newValue = parseInt(event.target.value, 10);
  
    if (isNaN(newValue) || newValue < 1 || newValue > maxOrderNumber) {
      newValue = row.orderNumber ?? 0; 
    } else {
      const copyCountingUoms: StockCheckUom[] = JSON.parse(JSON.stringify(countingUoms));
      const updatedCountingUoms = copyCountingUoms?.map(uom => 
        uom.id === row.id ? { ...uom, orderNumber: newValue } : uom,
      ) ?? [];
  
      setCountingUoms(updatedCountingUoms);
   
    }
  };

  const handleConfirmOrderNumber = () => {
    // Apply the reordering only when editing is finished
    const copyCountingUoms: StockCheckUom[] = JSON.parse(JSON.stringify(countingUoms));

    const reOrderCountingUoms = reorderCountingUoms(copyCountingUoms); 
    updateStockCheckUoms(reOrderCountingUoms);
  };

  const justOneVisible = () => {
    const visibleUoms = countingUoms?.filter(uom => uom.visible).length ?? 0; 
    return visibleUoms <= 1;
  };
  
  const hasReachedEightVisible = () => {
    const visibleUoms = countingUoms?.filter(uom => uom.visible).length ?? 0; 
    const value = visibleUoms >= 8;
    return value;
  };

  return  isLoading ?
    <LoadingWheel /> : <Grid container
      sx={{
        pt: '8px',
      }} >
   
      <Grid item
        sm={12}
        md={12}
        xs={12}
        sx={{ display: 'flex', justifyContent: 'flex-start', pb: '20px', pt:'20px' }}>
        <Typography  variant="textSM"
        >{t('countingUoms.warningMessage')}</Typography>
      </Grid>
      <Grid item
        xs={12} >
        <Box sx={{
          border: '1px solid',
          borderColor: theme.palette.custom.gray[200],
        }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '33.33%' }}
                    align="left">
                    {t('UOM')}
                  </TableCell>
                  <TableCell sx={{ width: '33.33%' }}
                    align="left">
                    <span>{t('countingUoms.labelVisible')}</span>
                    <Tooltip title={t('countingUoms.infoVisible')}>
                      <IconButton size="small">
                        <InfoIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ width: '33.33%' }}
                    align="left">
                    <span>{t('countingUoms.labelOrderNumber')}</span>
                    <Tooltip title={t('countingUoms.infoOrderOfColumn')}>
                      <IconButton size="small">
                        <InfoIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {countingUoms?.map((row) => (
                  <TableRow
                    hover
                    key={row.id}
                  >
                    <TableCell>
                      {row.uom}
                    </TableCell>
                    <TableCell>
                      <Switch
                        defaultChecked={row.visible}
                        onChange={(event, checked) => saveToggleButton(row, checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        disabled = {countingUomsIsSaving || !hasPermissionToWrite || (row.visible && justOneVisible()) || (!row.visible && hasReachedEightVisible()) }
                      />
                    </TableCell>
                
                    <TableCell>
                      { row.visible && <TextField
                        type="number"
                        size="small"
                        id="store-use"
                        name="store-use"
                        variant="outlined"
                        disabled = {countingUomsIsSaving || !hasPermissionToWrite}
                        sx={{ 
                          width: '80px',   
                        }}
                        value={row.orderNumber}
                        onChange={(event) => onValueChange(event, row)}  
                        onBlur={handleConfirmOrderNumber}
                        inputProps={{ min: 1, max: 8 }}
                      />}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>;
};

export default CountingUOMBox;