import {  Box, FormControl, Grid,  InputLabel,  MenuItem,  Select, SelectChangeEvent, Switch, TextField, Typography, useMediaQuery } from '@mui/material';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { theme } from '../../../theme';
import { TaskType, taskTypeList } from '../enums/IAutomatedTask';
import { ScheduleCreateContext, ScheduleCreateValues } from '../context/ScheduledReportsContext';
import { UserContext } from '../../../components/shared/useUser';
import { alseaLACFranchises } from '../../../enums/FranchiseNames';

interface AllTaskTypes {
  name: string;
  value: string;
  adminOnly: boolean;
}
const CreateScheduledReports =() => {
  const { 
    t,
    emailUser,
    active,
    name, 
    taskType,
    emails,
    saveUserReport,
    setName,
    handleChange,
    setEmails,
    setActive,
    setEmailUser,
    setSaveUserReport,

  } = useContext(ScheduleCreateContext) as ScheduleCreateValues;

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [sortedTaskTypeList, setSortedTaskTypeList] = useState<AllTaskTypes[]>();
  const [sortedAdminTaskTypeList, setSortedAdminTaskTypeList] = useState<AllTaskTypes[]>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const {  isAdmin, hasPermissionTo, selectedStore }: any = useContext(UserContext);
  const isAlseaLACFranchise = alseaLACFranchises.includes(selectedStore?.franchiseName ?? '');

  useEffect(() => {
    sortTaskType();
  }, []);

  const sortTaskType =() => {

    const filteredTaskTypeList = taskTypeList.filter(type => 
      (type.value !== TaskType.AutoApproveStockChecks || isAlseaLACFranchise),
    );

    const sortedTaskTypeList = filteredTaskTypeList.slice().sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    if (isAdmin()) { 
      const adminTaskTypes =  sortedTaskTypeList.filter(type =>type.adminOnly);
      setSortedAdminTaskTypeList(adminTaskTypes);
    }
    if (hasPermissionTo(['MadeInStoreRead'])){
      const nonAdminTaskTypeList  = sortedTaskTypeList.filter(type => !type.adminOnly || type.value == 'MadeInStoreReport');
      setSortedTaskTypeList(nonAdminTaskTypeList);
    }
    else {
      const nonAdminTaskTypeList = sortedTaskTypeList.filter(type => !type.adminOnly);
      setSortedTaskTypeList(nonAdminTaskTypeList);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmails(event.target.value);
  };
  
  const handleActivity = (event: SelectChangeEvent) => {
    setActive(!active);
  };

  const handleEmailUser = (event: SelectChangeEvent) => {
    setEmailUser(!emailUser);
    if(!emailUser){
      setSaveUserReport(true);
    }
  };

  const handleSaveEmailUser = (event: SelectChangeEvent) => {
    setSaveUserReport(!saveUserReport);
  };
 
  return (
    <Box sx={{ border:'2px solid', py: '12px', px: '8px', borderRadius:'4px', borderColor: theme.palette.primary[500] }}>
      <Grid container
        sx={{ p:'10px' }}>
        <Grid container
          spacing={5}
          flexWrap={isMobile ? 'wrap' : 'nowrap'}
        >
          <Grid item
            xs={12}
            md={6}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
            <FormControl fullWidth
              size='small'>
              <TextField
                label={t('name')}
                size='small'
                variant="outlined"
                value={name ?? ''}
                onChange={handleInputChange}
              >
              </TextField>
            </FormControl>
          </Grid>
          <Grid item
            xs={12}
            md={6}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
            <FormControl fullWidth
              size='small'>
              <InputLabel id="type-label">{t('taskType')}</InputLabel>
              <Select
                label={t('taskType')}
                size='small'
                variant="outlined"
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 200,
                    },
                  },
                }}
                value={taskType ?? ''}
                onChange={handleChange}
              >
                {sortedTaskTypeList?.map((option) => (
                  <MenuItem key={option.value}
                    value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
                { isAdmin() && (
                  <MenuItem disabled>{t('admin')}</MenuItem>
                )}
                {sortedAdminTaskTypeList?.map((option) => (
                  <MenuItem key={option.value}
                    value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container
          sx={{ pt:'10px' }}
          spacing={5}
          flexWrap={isMobile ? 'wrap' : 'nowrap'}>
          <Grid item
            xs={isMobile ? 12 : 6}
            md={5}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
            <FormControl fullWidth
              size='small'>
              <TextField 
                value={emails ?? ''}
                label={t('additionalEmails')}
                placeholder= {emails ??'Enter additional emails separated by ;'}
                size='small'
                variant="outlined"
                onChange={handleEmailChange}
              />
            </FormControl>
          </Grid>
          <Grid item
            xs={isMobile ? 12 : 2}
            md={3}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
            <Grid sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{t('active')}</Typography>
              <Switch
                checked={active}
                value={active}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={handleActivity}
              />
            </Grid>
          </Grid>
          <Grid item
            xs={ isMobile ? 12 : 2}
            md={3}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
            <Grid sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{t('emailUser')}</Typography>
              <Switch
                checked={ taskType === TaskType.SbuxGintWaves ? false : emailUser}
                value={ taskType === TaskType.SbuxGintWaves ? false : emailUser}
                disabled ={taskType === TaskType.SbuxGintWaves }
                onChange={handleEmailUser}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          </Grid>
          <Grid item
            xs={isMobile ? 12 : 2}
            md={3}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left'  }}>
            <Grid 
              sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{t('saveUserEmail')}</Typography>
              <Switch
                disabled ={taskType === TaskType.SbuxGintWaves || emailUser}
                value={taskType === TaskType.SbuxGintWaves ? false : saveUserReport}
                checked={ taskType === TaskType.SbuxGintWaves ? false : saveUserReport}
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={handleSaveEmailUser}
              />
            </Grid>
          </Grid> 
        </Grid>
      </Grid> 
    </Box>
  );
};

export default CreateScheduledReports;