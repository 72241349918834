import { ActionTypes, Numbers } from '../interfaces/IAutomated';

export enum ScheduleType {
  frequencyAndInterval = 0,
  monthlyDate,
  lastDayOfMonth
}

export enum RecursionType {
  Offset = 0,
  DayOfMonth = 1,
}
export enum TaskType {
  CostOfSalesReport = 'CostOfSalesReport',
  ItemActivityReport = 'ItemActivityReport',
  ItemTimeOutReport = 'ItemTimeOutReport',
  InvoiceRecReport = 'InvoiceRecReport',
  ProductMarginReport = 'ProductMarginReport',
  StockCountSummaryReport = 'StockCountSummaryReport',
  StockDaysReport = 'StockDaysReport',
  DcShortageReport = 'DcShortageReport',
  StockOnHandReport = 'StockOnHandReport',
  TheoryActualReport = 'TheoryActualReport',
  TransferReport = 'TransferReport',
  WasteReport = 'WasteReport',
  ReceiptsReport = 'ReceiptsReport',
  GenerateStockChecks = 'GenerateStockChecks',
  Maintenance = 'Maintenance',
  RoleReminder = 'RoleReminder',
  CreditPurchaseReport = 'CreditPurchaseReport',
  EzoraTheoryActualReport = 'EzoraTheoryActualReport',
  EzoraWasteReport = 'EzoraWasteReport',
  EuroGaragesTheoryActualReport = 'EuroGaragesTheoryActualReport',
  EuroGaragesWasteReport = 'EuroGaragesWasteReport',
  EuroGaragesCostOfSalesReport = 'EuroGaragesCostOfSalesReport',
  EuroGaragesItemActivityReport = 'EuroGaragesItemActivityReport',
  EuroGaragesItemTimeOutReport = 'EuroGaragesItemTimeOutReport',
  EuroGaragesStockDaysReport = 'EuroGaragesStockDaysReport',
  EuroGaragesStockOnHandReport = 'EuroGaragesStockOnHandReport',
  EuroGaragesTransferReport = 'EuroGaragesTransferReport',
  ScoreCardsIntegrationTransactions = 'ScoreCardsIntegrationTransactions',
  ScoreCardsIntegrationPurchaseOrders = 'ScoreCardsIntegrationPurchaseOrders',
  StockCountsVsUsageReport = 'StockCountsVsUsageReport',
  ArchiveInactiveItems = 'ArchiveInactiveItems',
  ShellStockSummaryReport = 'ShellStockSummaryReport',
  ErrorItemReport = 'ErrorItemReport',
  IntegrationExportStores = 'IntegrationExportStores',
  IntegrationExportItems = 'IntegrationExportItems',
  IntegrationExportPurchaseOrders = 'IntegrationExportPurchaseOrders',
  IntegrationExportTransactions = 'IntegrationExportTransactions',
  IntegrationExportTransactionFacts = 'IntegrationExportTransactionFacts',
  ExtendedStockCountsReport = 'ExtendedStockCountsReport',
  AutoReceivePurchaseOrders = 'AutoReceivePurchaseOrders',
  ConstrainedItemReport = 'ConstrainedItemReport',
  MacPercentageChange = 'MacPercentageChange',
  ItemStockOutReport = 'ItemStockOutReport',
  DsdExport = 'DsdExport',
  SbuxGintWaves = 'SbuxGintWaves',
  IntegrationExportMaterialTransactionFacts = 'IntegrationExportMaterialTransactionFacts',
  MadeInStoreReport = 'MadeInStoreReport',
  AutoApproveStockChecks = 'AutoApproveStockChecks'
}

export const taskTypeList: { name: string, value: string, adminOnly: boolean }[] = [
  { name: 'Cost Of Sales Report', value: 'CostOfSalesReport', adminOnly: false },
  { name: 'DC Shortage Report', value: 'DcShortageReport', adminOnly: false },
  { name: 'Item Activity Report', value: 'ItemActivityReport', adminOnly: false },
  { name: 'Item TimeOut Report', value: 'ItemTimeOutReport', adminOnly: false },
  { name: 'Invoice Reconciliation Report', value: 'InvoiceRecReport', adminOnly: false },
  { name: 'Product Margin Report', value: 'ProductMarginReport', adminOnly: false },
  { name: 'Stock Count Summary Report', value: 'StockCountSummaryReport', adminOnly: false },
  { name: 'Stock Days Report', value: 'StockDaysReport', adminOnly: false },
  { name: 'Stock On Hand Report', value: 'StockOnHandReport', adminOnly: false },
  { name: 'Theory Actual Report', value: 'TheoryActualReport', adminOnly: false },
  { name: 'Transfer Report', value: 'TransferReport', adminOnly: false },
  { name: 'Waste Report', value: 'WasteReport', adminOnly: false },
  { name: 'Receipts Report', value: 'ReceiptsReport', adminOnly: false },
  { name: 'Generate Stock Checks', value: 'GenerateStockChecks', adminOnly: true },
  { name: 'Maintenance', value: 'Maintenance', adminOnly: true },
  { name: 'Role Reminder', value: 'RoleReminder', adminOnly: true },
  { name: 'Credit Purchase Report', value: 'CreditPurchaseReport', adminOnly: false },
  { name: 'Ezora Theory Actual Report', value: 'EzoraTheoryActualReport', adminOnly: true },
  { name: 'Ezora Waste Report', value: 'EzoraWasteReport', adminOnly: true },
  { name: 'Euro Garages Theory Actual Report', value: 'EuroGaragesTheoryActualReport', adminOnly: true },
  { name: 'Euro Garages Waste Report', value: 'EuroGaragesWasteReport', adminOnly: true },
  { name: 'Euro Garages Cost Of Sales Report', value: 'EuroGaragesCostOfSalesReport', adminOnly: true },
  { name: 'Euro Garages Item Activity Report', value: 'EuroGaragesItemActivityReport', adminOnly: true },
  { name: 'Euro Garages Item TimeOut Report', value: 'EuroGaragesItemTimeOutReport', adminOnly: true },
  { name: 'Euro Garages Stock Days Report', value: 'EuroGaragesStockDaysReport', adminOnly: true },
  { name: 'Euro Garages Stock On Hand Report', value: 'EuroGaragesStockOnHandReport', adminOnly: true },
  { name: 'Euro Garages Transfer Report', value: 'EuroGaragesTransferReport', adminOnly: true },
  { name: 'Score Cards Integration Transactions', value: 'ScoreCardsIntegrationTransactions', adminOnly: true },
  { name: 'Score Cards Integration Purchase Orders', value: 'ScoreCardsIntegrationPurchaseOrders', adminOnly: true },
  { name: 'Stock Counts Vs Usage Report', value: 'StockCountsVsUsageReport', adminOnly: false },
  { name: 'Archive Inactive Items', value: 'ArchiveInactiveItems', adminOnly: true },
  { name: 'Shell Stock Summary Report', value: 'ShellStockSummaryReport', adminOnly: true },
  { name: 'Item Error Report', value: 'ErrorItemReport', adminOnly: true },
  { name: 'Export Stores', value: 'IntegrationExportStores', adminOnly: true },
  { name: 'Export Items', value: 'IntegrationExportItems', adminOnly: true },
  { name: 'Export Purchase Orders', value: 'IntegrationExportPurchaseOrders', adminOnly: true },
  { name: 'Export Transactions', value: 'IntegrationExportTransactions', adminOnly: true },
  { name: 'Export Transaction Facts', value: 'IntegrationExportTransactionFacts', adminOnly: true },
  { name: 'Extended Stock Count Export', value: 'ExtendedStockCountsReport', adminOnly: false },
  { name: 'Auto Receive Purchase Orders', value: 'AutoReceivePurchaseOrders', adminOnly: true },
  { name: 'Constrained Item Report', value: 'ConstrainedItemReport', adminOnly: false },
  { name: 'MAC Percentage Change', value: 'MacPercentageChange', adminOnly: false },
  { name: 'Item Stock Out Report', value: 'ItemStockOutReport', adminOnly: false },
  { name: 'DSD Export', value: 'DsdExport', adminOnly: true },
  { name: 'Gint Waves', value: 'SbuxGintWaves', adminOnly: true },
  { name: 'Export Material Transaction Facts', value: 'IntegrationExportMaterialTransactionFacts', adminOnly: true },
  { name: 'Made in Store Report', value: 'MadeInStoreReport', adminOnly: true },
  { name: 'Auto Approve Stock Checks', value: 'AutoApproveStockChecks', adminOnly: true },
];

export const gintWaves: { name: string, waveNumber: string }[] = [
  { name: 'Sales - Standalone SKU', waveNumber: '11' },
  { name: 'Returns - Standalone SKU', waveNumber: '12' },
  { name: 'Sales - Parent SKU', waveNumber: '13' },
  { name: 'Returns - Parent SKU', waveNumber: '14' },
  { name: 'Receipts', waveNumber: '31' },
  { name: 'Transfer In', waveNumber: '41' },
  { name: 'Transfer Out', waveNumber: '42' },
  { name: 'In Transit', waveNumber: '53' },
  { name: 'Stock Adjustment', waveNumber: '71' },
  { name: 'Partner Markouts', waveNumber: '79' },
  { name: 'Waste', waveNumber: '80' },
];

export const recursionTypes: Numbers[] = [
  { description: 'Select Recursive Type' },
  { description: 'Offset' },
  { description: 'Day of month' },
];
export const scheduleType: ActionTypes[] = [
  { index: 0, description: 'Frequency and Interval' },
  { index: 1, description: 'Monthly Date' },
  { index: 2, description: 'Last day of month' },
];
export const allStatuses: ActionTypes[] = [
  { index: 0, description: 'Frequency and Interval' },
  { index: 1, description: 'Monthly Date' },
  { index: 2, description: 'Last day of month' },
];
export const frequencyType: ActionTypes[] = [
  { index: 0, description: 'Select Frequency' },
  { index: 1, description: 'Day' },
  { index: 2, description: 'Week' },
];
export const actionTypes: Numbers[] = [
  { description: '-1' },
  { description: '-2' },
  { description: '-3' },
  { description: '-4' },
  { description: '-5' },
  { description: '-6' },
  { description: '-7' },
  { description: '-8' },
  { description: '-9' },
  { description: '-10' },
  { description: '-11' },
  { description: '-12' },
  { description: '-13' },
  { description: '-14' },
  { description: '-15' },
  { description: '-16' },
  { description: '-17' },
  { description: '-18' },
  { description: '-19' },
  { description: '-20' },
  { description: '-21' },
  { description: '-22' },
  { description: '-23' },
  { description: '-24' },
  { description: '-25' },
  { description: '-26' },
  { description: '-27' },
  { description: '-28' },
  { description: '-29' },
  { description: '-30' },
];
export const days: ActionTypes[] = [
  { index: 1, description: '1' },
  { index: 2, description: '2' },
  { index: 3, description: '3' },
  { index: 4, description: '4' },
  { index: 5, description: '5' },
  { index: 6, description: '6' },
  { index: 7, description: '7' },
  { index: 8, description: '8' },
  { index: 9, description: '9' },
  { index: 10, description: '10' },
  { index: 11, description: '11' },
  { index: 12, description: '12' },
  { index: 13, description: '13' },
  { index: 14, description: '14' },
  { index: 15, description: '15' },
  { index: 16, description: '16' },
  { index: 17, description: '17' },
  { index: 18, description: '18' },
  { index: 19, description: '19' },
  { index: 20, description: '20' },
  { index: 21, description: '21' },
  { index: 22, description: '22' },
  { index: 23, description: '23' },
  { index: 24, description: '24' },
  { index: 25, description: '25' },
  { index: 26, description: '26' },
  { index: 27, description: '27' },
  { index: 28, description: '28' },
  { index: 29, description: '29' },
  { index: 30, description: '30' },
];
