import { Box, Button, Grid, Stack, SvgIcon, Typography } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Save01, Lock01, LockUnlocked01, Edit05, Plus, Download01 } from '../../../assets';
import { PurchaseOrderDetailsContext, PurchaseOrderDetailsValues } from '../contexts/PurchaseOrderDetailsContext';
import LoadingWheel from '../../../components/ui/LoadingWheel';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../../components/shared/PageTitle';
import BackButton from '../../../components/shared/actions/BackButton';
import LeavePageModal from '../../../components/shared/dialogs/LeavePageModal';

const PurchaseOrdersDetailsTop = () => {
  const { saveClicked,
    addItemClicked,
    isReceiving,
    isLoading,
    showAdjustButton,
    isProcessing,
    adjustClicked,
    downloadClicked,
    showSave,
    cannotOverReceive,
    purchaseOrders,
    canStoreAddItemsToPO,
    showAutosaveMessage,
    handleLeavePageModalConfirm,
    handleLeavePageModalClose,
    handleLeavePageModalCancel,
    blocker,
    modifiedPOLines,
    showOverReceivingWarningModal } = useContext(PurchaseOrderDetailsContext) as PurchaseOrderDetailsValues;

  const navigate = useNavigate();
  const { t } = useTranslation('purchaseOrderDetails');

  return <>
    <BackButton
      handleClick={() => navigate('/store/po-receipt')}
      title={t('goBack')}
      isLink={false}
      styles={{ marginBottom: '20px' }}
    />
    <PageTitle>
      {t('title')}: {purchaseOrders?.orderNumber}
    </PageTitle>
    {isLoading ? <LoadingWheel /> :
      <> <Grid container
        sx={{ pb: '8px' }}>
        <Grid item
          xs={6}>
          <Stack spacing={3}>
            <Box>
              <Typography variant='textLG'>
                {t('status')}: {isReceiving ?
                  <>{t('received')} <SvgIcon sx={{ verticalAlign: '-10%', fontSize: 'inherit' }}><Lock01 /></SvgIcon></>
                  :
                  <>{t('notReceived')} <SvgIcon sx={{ verticalAlign: '-10%', fontSize: 'inherit' }}><LockUnlocked01 /></SvgIcon></>}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item
          sx={{ textAlign: 'right' }}
          xs={6}>
          <Button variant="secondary"
            size="lg"
            sx={{ verticalAlign: 'bottom', marginRight: '5px' }}
            onClick={downloadClicked}
            disabled={isProcessing}
            startIcon={<SvgIcon> <Download01 /> </SvgIcon>}>
            {t('download')}
          </Button>
          {showSave && canStoreAddItemsToPO &&
            <Button variant="primary"
              size="lg"
              sx={{ verticalAlign: 'bottom', mr: '4px' }}
              onClick={addItemClicked}
              disabled={isProcessing}
              startIcon={<SvgIcon> <Plus /> </SvgIcon>}>
              {t('addItem')}
            </Button>
          }
          {showSave && <>
            <Button variant="primary"
              size="lg"
              sx={{ verticalAlign: 'bottom' }}
              onClick={saveClicked}
              disabled={isProcessing || cannotOverReceive}
              startIcon={<SvgIcon> <Save01 /> </SvgIcon>}>
              {t('save')}
            </Button>
            <Grid item
              xs={12}
              sx={{ textAlign: 'right' }}>
              <Typography variant='textLG'
                sx={{
                  visibility: !showOverReceivingWarningModal && showAutosaveMessage ? 'visible' : 'hidden',
                }}
              >
                {t('changesSaved')}
              </Typography>
            </Grid>
          </>
          }
          {showAdjustButton &&
            <Button variant="primary"
              size="lg"
              sx={{ verticalAlign: 'bottom' }}
              onClick={adjustClicked}
              disabled={isProcessing}
              startIcon={<SvgIcon> <Edit05 /> </SvgIcon>}>
              {t('adjustBtn')}
            </Button>
          }
        </Grid>
      </Grid> 
      <LeavePageModal
        open={(blocker.state === 'blocked' && modifiedPOLines && modifiedPOLines.size > 0) ?? false}
        onConfirm={handleLeavePageModalConfirm}
        onClose={handleLeavePageModalClose}
        onCancel={handleLeavePageModalCancel}
        onConfirmUrl='/store/daily-waste-checksheet'
        leaveWarningContent={t('saveOnLeaveWarningContent')}
        leaveWarningTitle={t('saveOnLeaveWarningTitle')} />
      </>
    }
  </>;
};

export default PurchaseOrdersDetailsTop;