import { Box, Tab, Tabs, Typography } from '@mui/material';
import ScheduleBox from './components/ScheduleBox';
import useNswagClient from '../../hooks/api/useNswagClient';
import { useContext, useEffect, useState } from 'react';
import { StockCheckScheduleDetail } from '../../app/services/api/generated';
import { UserContext } from '../../components/shared/useUser';
import { useTranslation } from 'react-i18next';
import PageTitle from '../../components/shared/PageTitle';
import PageArea from '../../components/shared/PageArea';
import CountingUOMBox from './components/CountingUomsBox';
import { ConfigurationName } from '../../enums/ConfigurationName';
import useLogError from '../../hooks/useLogError';

const StockCountSchedule = () => {
  const { getsStockCheckSchedule, getConfiguration } = useNswagClient();
  const { t } = useTranslation('common');
  const { selectedStore } = useContext(UserContext);
  const storeID = selectedStore?.storeNumber ? selectedStore.storeNumber : 'No store selected';
  const isStoreSelected = () => { if (selectedStore == null) { return false; } else { return true; }};
  const [schedules, setSchedules] = useState<StockCheckScheduleDetail[]>([]);
  const [tabValue, setTabValue] = useState<string>('one');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCountingInDifferentUom, setIsCountingInDifferentUom] = useState<boolean>(false);
  const { logError } = useLogError();

  useEffect(() => {
    loadData();
    getCountingInDifferentUOMConfig();
  }, [selectedStore]);

  const loadData = () => {
    setIsLoading(true);
    getsStockCheckSchedule(storeID).then((result) => {
      if (result?.data) {
        setSchedules(result.data);
      }
    })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => (setIsLoading(false)));
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const getCountingInDifferentUOMConfig = () => {
    getConfiguration(ConfigurationName.CountingInDifferentUoms, selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((config: any) => {
        if (config.data.value === 'true') {
          setIsCountingInDifferentUom(true);
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((e: any) => logError(e));
  };

  return (
    <Box sx={{ width: '100%', textAlign: 'left' }}>
      <PageArea>
        <PageTitle>{t('stockCountManagement')}</PageTitle>
        {isStoreSelected() ? <>
          <Tabs value={tabValue}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="primary tabs example">
            <Tab label={ t('scheduledStockCounts') }
              value="one" />
            {isCountingInDifferentUom && <Tab label={ t('countingUOMs') }
              value="two" />}
          </Tabs>
          {tabValue === 'one' && <ScheduleBox schedules={schedules}
            storeID={storeID}
            loadData={loadData}
            isLoading={isLoading}></ScheduleBox>}
          {tabValue === 'two' &&  isCountingInDifferentUom && <CountingUOMBox></CountingUOMBox>}
        </>
          : <Box><Typography variant='textLG'>No store selected</Typography></Box>}
      </PageArea>
    </Box>
  );
};

export default StockCountSchedule;