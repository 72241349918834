import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { theme } from '../../../theme';
import { useContext } from 'react';
import { CreateInvoicesValues, InvoicesContext } from '../contexts/InvoicesContext';
import PageTitle from '../../../components/shared/PageTitle';
import { Pill } from '../../../components/shared/Pill';

export const InvoiceTitle = () => {
  const { t, totalRows } = useContext(InvoicesContext) as CreateInvoicesValues;

  return (
    <Typography
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        alignSelf: 'stretch',
      }}
    >
      <PageTitle>{t('title')}</PageTitle>
      <Typography
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '2px 8px',
          borderRadius: '16px',
          border: '1px solid',
          borderColor: theme.palette.primary[200],
          background: theme.palette.primary[50],
          fontSize: '0.875rem',
          fontWeight: 500,
        }}
      >
        {`${totalRows} ${t('items')}`}
      </Typography>
    </Typography>
  );
};
