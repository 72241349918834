export enum ConfigurationName {
    AuditEmail,
    SmartStockCountsEnabled,
    SmartStockCountInactivityDays,
    SmartStockCountExcludeRemovedItemsDays,
    CurrencyCode,
    MaxToleranceWarningValue,
    CreditRequestsEnabled,
    OneClickReceiveEnabled,
    AutoReceiveEnabled,
    PurchaseOrderAdjustmentsEnabled,
    SalesForecastEnabled,
    CreditRequestEvidenceRequired,
    DailyWasteCheckSheetEnabled,
    RecipeAutoUpdates,
    ItemAutoUpdates,
    PosStoreNumberOverride,
    MadeInStore,
    PosIntegration,
    COG,
    ReturnToVendorEnabled,
    AddItemsInPoEnabled,
    TariffCodeEnabled,
    ItemNumberPattern,
    RecentActivityPeriod,
    LocationsEnabled,
    PaperPackagingDepletionEnabled,
    RefundsBackToStockEnabled,
    BIFeedsEnabled,
    DisplayPoDetails,
    PurchaseOrderReceivingLocalisation,
    PurchaseOrdersDetailsDownload,
    TimeZone,
    CostPriceModel,
    CupLidType,
    BarcodeScanning,
    ReceiptTransactionDate,
    CountingInDifferentUoms,
    FranchiseDynamicBranding,
    SubLocalisationsEnabled,
    TaxRatePercentage,
    CanViewFuturePOEnabled,
    CreatePurchaseOrderEnabled,
    ReportBuilderEnabled
}