import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { applyRoundAndFormat, applyRoundAndFormatDecimal } from '../../utils';

interface ComponentProps {
  value: number;
  currencyCode?: string;
  useCurrency?: boolean;
}

const isDecimal = (num: number) => {
  return num % 1 !== 0;
};

const RoundAndFormatValue: React.FC<ComponentProps> = ({ value, currencyCode, useCurrency = true }) => {
  const isNumberDecimal = isDecimal(value);

  const formattedValue = useCurrency ? applyRoundAndFormat(value, currencyCode) : applyRoundAndFormatDecimal(value);
  const totalValue = useCurrency ? applyRoundAndFormat(value, currencyCode, 5) : applyRoundAndFormatDecimal(value, 5);

  return (
    <Tooltip title={isNumberDecimal ? totalValue : formattedValue}>
      <Box sx={{ display: 'inline-block' }}>
        <Typography
          sx={{ textDecoration: isNumberDecimal ? 'underline' : '' }}
          variant='textXS'
        >
          {formattedValue}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default RoundAndFormatValue;