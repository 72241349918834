export const starbucksFranchises = [
  'STARBUCKS UK',
  'STARBUCKS ITALY',
  'STARBUCKS SWITZERLAND',
  'STARBUCKS AUSTRIA',
];
export const alseaLACFranchises = [
  'Alsea Mexico',
  'Alsea Chile',
  'Alsea Colombia',
];