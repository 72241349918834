import { Dialog, DialogTitle, Typography, IconButton, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, TextField, Grid, Autocomplete, SvgIcon, FormControlLabel, Checkbox, FormHelperText, DialogContentText } from '@mui/material';
import { SearchSm, StickerSquare, X } from '../../../assets';
import { useTranslation } from 'react-i18next';
import React, { FC, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { CreateMadeInStoreItemRequestDto, Item, ItemComponent, MadeInStoreType, RecipeComponent, Store } from '../../../app/services/api/generated';
import { UserContext } from '../../../components/shared/useUser';
import RecipeComponentRow from './RecipeComponentRow';
import useCategoriesData from '../hooks/useCategoriesData';
import useStockItemsData from '../hooks/useStockItemsData';
import { useRecipeDialog } from '../context/RecipeDialogContext';
import { theme } from '../../../theme';
import { ConfigurationName } from '../../../enums/ConfigurationName';
import useNswagClient from '../../../hooks/api/useNswagClient';
import useLogError from '../../../hooks/useLogError';

type EntityType = {
  value: number;
  label: string;
};

let entityTypes: EntityType[] = [
  {
    value: 0,
    label: 'Item',
  },
  {
    value: 1,
    label: 'Recipe',
  },
  {
    value: 2,
    label: 'Made In Store',
  },
];


const primaryUoms: string[] = ['EA', 'CS'];

type Props = {
  onCreateConfirm: (recipe: CreateMadeInStoreItemRequestDto) => void;
  onUpdateConfirm: (entityId: number, entityType: MadeInStoreType, added: ItemComponent[], updated: ItemComponent[], deleted: ItemComponent[], updateForFranchise: boolean) => void;
  isUpdating: boolean;
  isComplete: number;
}

const CreateRecipeDialog: FC<Props> = ({ onCreateConfirm, onUpdateConfirm, isUpdating, isComplete }) => {
  const { t } = useTranslation('recipe');
  const { selectedStore, hasPermissionTo, user } = useContext(UserContext);

  const { showRecipeDialog, setShowRecipeDialog, initialFormState, formState, setFormState, updateFormField, resetFormState, isEditMode, setIsEditMode } = useRecipeDialog();
  const { allCategories, loadCategories } = useCategoriesData();
  const { getConfiguration, getStoreItems } = useNswagClient();
  const { stockItems, setStockItems, loadStockItems } = useStockItemsData();
  const { logError } = useLogError();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [selectedStockItem, setSelectedStockItem] = useState(null);
  const [isInvalidForm, setIsInvalidForm] = useState<boolean>(false);
  const [isInvalidFormat, setIsInvalidFormat] = useState<boolean>(false);
  const [isExistingFormat, setIsExistingFormat] = useState<boolean>(false);

  const [checkboxState, setCheckboxState] = useState(false); // Track checkbox state
  const [showConfirmModal, setShowConfirmModal] = useState(false); // Track modal confirm
  const [pendingState, setPendingState] = useState(false); // Track the state pending confirmation

  const [updateForFranchise, setUpdateForFranchise] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);

  const errorMessage = t('requiredErrorMessage');
  const invalidFormatMessage = t('invalidFormatErrorMessage');
  const existingFormatMessage = t('existingFormatErrorMessage');

  useEffect(() => {
    if (isComplete) {
      onCancel();
    }
  }, [isComplete]);

  useEffect(() => {
    if (selectedStore) {
      loadStockItems(selectedStore.storeNumber ?? '');
      loadCategories(selectedStore.storeNumber ?? '');
    }
  }, [selectedStore]);

  useEffect(() => {
    setFormState({
      ...formState,
      selectedStores: formState.isAllStores ? (user?.stores ?? []) : formState.selectedStores,
    });
  }, [user?.stores]);

  useEffect(() => {
    if (typeof formState?.updateForFranchise === 'boolean') {
      setCheckboxState(formState.updateForFranchise);
    }
  }, [formState]);

  useEffect(() => {
    if (isComplete) {
      setShowRecipeDialog(false);
    }
  }, [isComplete]);


  const onStoreChange = (event: SyntheticEvent, value: Store[] | null) => {
    if (value) {
      setFormState({
        ...formState,
        selectedStores: value,
      });
      markFormChange();
    }
  };

  const itemToRecipeComponent = (item: Item): RecipeComponent => {
    const recipeComponent: RecipeComponent = {
      quantity: 0,
      weight: 0,
      componentId: item.id,
      componentItemNumber: (item?.itemNumber ?? '').toString(),
      componentDescription: item.description,
      componentWeightNet: item.weightNet,
      componentCostPriceOnHand: item.costPrice,
      uom: item.uom,
      volume: 0,
      componentVolume: item.itemVolume,
      updateForFranchise: formState.updateForFranchise,
    };
    return recipeComponent;
  };

  const onStockItemChange = (event: SyntheticEvent, value: Item | null) => {
    if (value) {
      const newRecipeComponent: RecipeComponent = {
        quantity: 0,
        weight: 0,
        componentId: value.id,
        componentItemNumber: value.itemNumber,
        componentDescription: value.description,
        componentWeightNet: value.weightNet,
        componentCostPriceOnHand: value.costPrice,
        uom: value.uom,
        volume: 0,
        componentVolume: value.itemVolume,
        updateForFranchise: checkboxState,
      };

      setFormState((prevState) => ({
        ...prevState,
        recipeComponents: [...prevState.recipeComponents, newRecipeComponent],
      }));
      markFormChange();
    }
  };


  const handleSelectAllStoresChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    setFormState({
      ...formState,
      selectedStores: checked ? (user?.stores ?? []) : [],
      isAllStores: checked,
    });
    markFormChange();
  };

  const handleRecipeComponentChanges = (index: number, field: 'weight' | 'quantity' | 'volume', value: string) => {
    const updatedComponent = formState.recipeComponents.find((component, i) =>
      i === index,
    );
    if (updatedComponent) {
      switch (field) {
      case 'weight':
        updatedComponent.weight = Number(value);
        if (updatedComponent.componentWeightNet && updatedComponent.componentWeightNet > 0) {
          updatedComponent.quantity = Number((Number(value) / updatedComponent.componentWeightNet).toFixed(8));
          if (updatedComponent.componentVolume && updatedComponent.componentVolume > 0) {
            updatedComponent.volume = Number((updatedComponent.quantity * updatedComponent.componentVolume).toFixed(8));
          }
        }
        break;
      case 'quantity':
        updatedComponent.quantity = Number(value);
        if (updatedComponent.componentWeightNet && updatedComponent.componentWeightNet > 0) {
          updatedComponent.weight = Number((Number(updatedComponent.quantity) * updatedComponent.componentWeightNet).toFixed(8));
        }
        if (updatedComponent.componentVolume && updatedComponent.componentVolume > 0) {
          updatedComponent.volume = Number((Number(updatedComponent.quantity) * updatedComponent.componentVolume).toFixed(8));
        }
        break;
      case 'volume':
        updatedComponent.volume = Number(value);
        if (updatedComponent.componentVolume && updatedComponent.componentVolume > 0) {
          updatedComponent.quantity = Number((Number(updatedComponent.volume) / updatedComponent.componentVolume).toFixed(8));
          if (updatedComponent.componentWeightNet && updatedComponent.componentWeightNet > 0) {
            updatedComponent.weight = Number((updatedComponent.quantity * updatedComponent.componentWeightNet).toFixed(8));
          }
        }
        break;
      default:
        break;
      }

      setFormState({
        ...formState,
        recipeComponents: formState.recipeComponents.map((item, i) => (i === index ? updatedComponent : item)),
      });
      markFormChange();
    }
  };

  const handleRemoveComponent = (index: number) => {
    setFormState({
      ...formState,
      recipeComponents: formState.recipeComponents.filter((_, i) => i !== index),
    });
    markFormChange();
  };

  const isItemNumberValid = () => {
    const existingItemNumberPattern = getItemNumberPattern();
    const itemNumberPattern = existingItemNumberPattern ? new RegExp(existingItemNumberPattern) : /^[a-zA-Z0-9]{0,9}$/;
    return itemNumberPattern.test(formState.itemNumber);
  };

  const getInvalidItemNumberMessage = () => {
    let helperText = '';
    if (isInvalidFormat) {
      helperText = invalidFormatMessage;
    } else if (isExistingFormat) {
      helperText = existingFormatMessage;
    } else if (isInvalidForm && formState.itemNumber === '') {
      helperText = errorMessage;
    }
    return helperText;
  };

  const getItemNumberPattern = () => {
    let configValue = '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getConfiguration(ConfigurationName.ItemNumberPattern, selectedStore?.storeNumber ?? '', selectedStore?.franchiseName ?? '').then((res: any) => {
      configValue = res.data?.value;
    });

    return configValue;
  };

  const isDuplicateItemNumber = async () => {
    const storeNumbers: string[] = (formState.selectedStores ?? []).filter(s => s?.storeNumber !== undefined && s?.storeNumber !== null).map(s => s.storeNumber) as string[];
    try {
      const response = await getStoreItems({
        itemNumber: formState.itemNumber,
        storeNumbers: storeNumbers,
      });
      const { items } = response;
      return Boolean(items?.length > 0);
    } catch (error) {
      logError(error);
      return false;
    }
  };

  const isRecipeFormValid = (): boolean => {
    const isValid = formState.description !== ''
      && formState.itemNumber !== ''
      && (isEditMode ? true : formState.selectedCategory !== '')
      && (formState.selectedStores && formState.selectedStores.length !== 0 || false);

    return isValid;
  };

  const isItemFormValid = (): boolean => {
    return formState.description !== ''
      && formState.itemNumber !== ''
      && (isEditMode ? true : formState.selectedCategory !== '')
      && formState.primaryUOM !== ''
      && (formState.weight !== 0 && String(formState.weight) !== '')
      && (formState.uomConversion !== 0 && String(formState.uomConversion) !== '')
      && (formState.unitCostPrice !== 0 && String(formState.unitCostPrice) !== '')
      && (formState.selectedStores && formState.selectedStores.length !== 0 || false);
  };

  const validateComponents = (): boolean => {
    let invalidFieldsInComponent = false;

    if (formState.recipeComponents.length === 0) {
      setIsInvalidForm(true);
      return false;
    }

    for (const item of formState.recipeComponents) {
      if (!item.quantity || item.quantity <= 0 || !item.weight || item.weight <= 0) {
        invalidFieldsInComponent = true;
        break;
      }
    }
    return !invalidFieldsInComponent;
  };

  const findArrayDifference = (oldArray: ItemComponent[], newArray: ItemComponent[]): {
    added: ItemComponent[],
    updated: ItemComponent[],
    deleted: ItemComponent[]
  } => {
    const added: ItemComponent[] = [];
    const updated: ItemComponent[] = [];
    const deleted: ItemComponent[] = [];

    newArray.forEach(newItem => {
      const oldItem = oldArray.find(item => item.componentId === newItem.componentId);
      if (oldItem) {
        if (JSON.stringify(oldItem) !== JSON.stringify(newItem)) {
          updated.push(newItem);
        }
      } else {
        added.push(newItem);
      }
    });

    oldArray.forEach(oldItem => {
      if (!newArray.find(item => item.componentId === oldItem.componentId)) {
        deleted.push(oldItem);
      }
    });

    return { added, updated, deleted };
  };

  const onUpdate = (recipe: CreateMadeInStoreItemRequestDto) => {
    const { recipeComponents, id } = initialFormState;

    const updateForFranchise = formState.updateForFranchise;

    if (recipeComponents && id && recipe.type) {
      // Updated components with the franchise value
      const updatedComponents: ItemComponent[] = recipeComponents.map(component => ({
        ...component,
        itemId: id,
        updateForFranchise: updateForFranchise,
      }));

      const { added, updated, deleted } = findArrayDifference(
        updatedComponents,
        recipe.components ?? [],
      );

      onUpdateConfirm(id, recipe.type, added, updated, deleted, updateForFranchise);
    }
  };


  const createNewItem = async () => {
    const isValid = isItemFormValid();
    const isValidFormat = isItemNumberValid();
    const isExisting = await isDuplicateItemNumber();
    setIsInvalidFormat(!isValidFormat);
    setIsInvalidForm(!isValid);
    setIsExistingFormat(isExisting);

    if (isValid && !isExisting && isValidFormat) {
      const item: CreateMadeInStoreItemRequestDto = {
        type: formState.selectedEntityType as MadeInStoreType,
        description: formState.description,
        itemNumber: formState.itemNumber,
        primaryUom: formState.primaryUOM,
        uomConversion: Number(formState.uomConversion),
        unitCostPrice: Number(formState.unitCostPrice),
        weightNet: Number(formState.weight),
        isAllStores: formState.isAllStores,
        stores: formState.isAllStores ? (user?.stores ?? []).map(x => x.storeNumber) as string[] : (formState.selectedStores?.map(x => x.storeNumber) ?? []) as string[],
        category: formState.selectedCategory,
        components: [],
        updateForFranchise: formState.updateForFranchise,
      };

      onCreateConfirm(item);
    }
  };

  const saveRecipe = async (): Promise<boolean> => {
    const isValid = isRecipeFormValid() && validateComponents();
    const isValidFormat = isItemNumberValid();

    setIsInvalidFormat(!isValidFormat);
    setIsInvalidForm(!isValid);

    if (!isValid || !isValidFormat) return false;

    let isExisting = false;
    if (!isEditMode) {
      isExisting = await isDuplicateItemNumber();
      setIsExistingFormat(isExisting);
    }

    if (isExisting) return false;

    const recipe = createRecipeObject();

    try {
      if (isEditMode) {
        onUpdate(recipe);
      } else {
        await onCreateConfirm(recipe);
      }
      return true;
    } catch (error) {
      logError(error);
      return false;
    }
  };


  const createRecipeObject = () => {
    return {
      type: formState.selectedEntityType as MadeInStoreType,
      description: formState.description,
      itemNumber: formState.itemNumber,
      primaryUom: formState.primaryUOM,
      uomConversion: Number(formState.uomConversion),
      unitCostPrice: Number(formState.unitCostPrice),
      weightNet: Number(formState.weight),
      isAllStores: formState.isAllStores,
      stores: formState.isAllStores ? (user?.stores ?? []).map(x => x.storeNumber) as string[] : (formState.selectedStores?.map(x => x.storeNumber) ?? []) as string[],
      category: formState.selectedCategory,
      components: formState.recipeComponents,
      updateForFranchise: formState.updateForFranchise,
    };
  };


  const onSaveRecipe = async () => {
    setIsSaving(true);
    if (formState.selectedEntityType === 0) {
      await createNewItem();
    } else {
      const isSuccess = await saveRecipe();
      if (isSuccess) {
        setShowRecipeDialog(false);
      }
    }
    setIsSaving(false);
  };

  const onCancel = () => {
    if (!isSaving) {
      setShowRecipeDialog(false);
      setIsInvalidForm(false);
      resetFormState(user?.stores ?? []);
      setIsEditMode(false);
      setIsDirty(false);
    }
  };


  const updateFormFieldAndMarkAsDirty = (fieldName: string, value: string | number) => {
    updateFormField(fieldName, value);
    markFormChange();
  };

  const markFormChange = () => {
    if (!isDirty) {
      setIsDirty(true);
    }
  };

  const isSaveButtonDisabled = () => {
    return isUpdating
      || !isDirty
      || !isRecipeFormValid()
      || !isItemNumberValid()
      || (formState.selectedEntityType === 0 && !isItemFormValid());
  };

  if (!hasPermissionTo(['MadeInStoreRead'])) {
    entityTypes = entityTypes.filter(type => type.label !== 'Made In Store');
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setPendingState(isChecked);
      setShowConfirmModal(true);
    } else {
      setCheckboxState(isChecked);
      setFormState((prevState) => ({
        ...prevState,
        updateForFranchise: isChecked,
        recipeComponents: prevState.recipeComponents.map(component => ({
          ...component,
          updateForFranchise: isChecked,
        })),
      }));
      markFormChange();
    }
  };


  const handleConfirm = () => {
    setCheckboxState(pendingState);
    setFormState((prevState) => ({
      ...prevState,
      updateForFranchise: pendingState,
      recipeComponents: prevState.recipeComponents.map(component => ({
        ...component,
        updateForFranchise: pendingState,
      })),
    }));
    markFormChange();
    setShowConfirmModal(false);
  };

  const handleCancel = () => {
    setPendingState(false);
    setShowConfirmModal(false);
  };

  return (
    <Dialog
      open={showRecipeDialog}
      onClose={onCancel}
      maxWidth='md'
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
        <SvgIcon sx={{ width: '20px', mr: '5px' }}><StickerSquare /></SvgIcon>
        <Typography variant="textXL"
          sx={{ display: 'flex', flex: 1, justifyContent: 'left', alignItems: 'center' }}>
          {isEditMode ? 'Edit' : 'Create'} {formState.selectedEntityType === 0 ? 'Item' : 'Recipe/Made In Store item'}
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.custom.gray[500],
        }}
      >
        <X />
      </IconButton>
      <DialogContent>
        <Grid container
          spacing={9}
          sx={{ pt: '10px' }}
        >
          <Grid item
            xs={12}>
            <FormControl fullWidth>
              <InputLabel>{t('type')}</InputLabel>
              <Select
                value={formState.selectedEntityType}
                label={t('type')}
                onChange={(e) => updateFormFieldAndMarkAsDirty('selectedEntityType', Number(e.target.value))}
                disabled={isEditMode}
              >
                {entityTypes.map((type) => (
                  <MenuItem key={type.value}
                    value={type.value}>{type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item
            xs={isEditMode ? 9 : 12}>
            <FormControl fullWidth>
              <TextField
                label={t('description')}
                value={formState.description}
                onChange={(e: { target: { value: string | number } }) =>
                  updateFormFieldAndMarkAsDirty('description', e.target.value)
                }
                disabled={isEditMode}
                variant="outlined"
                fullWidth
                required
                error={isInvalidForm && formState.description === ''}
                helperText={isInvalidForm && formState.description === '' ? errorMessage : ''}
              />
            </FormControl>
          </Grid>

          {isEditMode && selectedStore?.franchiseName === 'NE Apple Green GB' && (
            <Grid item
              xs={12}
              sm={6}
              md={3}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mb: 2 }}>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkboxState}
                    onChange={handleCheckboxChange}
                  />
                }
                label={t('updateForFranchise')}
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                  },
                }} />
            </Grid>
          )}


          <Dialog
            open={showConfirmModal}
            onClose={handleCancel}
          >
            <DialogTitle>{t('confirmUpdate')}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('confirmUpdateMessage')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel}
                variant="secondary"
                color="secondary">
                {t('cancel')}
              </Button>
              <Button onClick={handleConfirm}
                variant="primary"
                color="primary">
                {t('confirm')}
              </Button>
            </DialogActions>
          </Dialog>

          <Grid item
            sm={12}
            md={6}
          >
            <FormControl fullWidth
              size="small">
              <InputLabel id="category-label">{t('category')}</InputLabel>
              <Select
                labelId="category-label"
                value={formState.selectedCategory}
                label={t('category')}
                onChange={(e) => updateFormFieldAndMarkAsDirty('selectedCategory', e.target.value)}
                disabled={isEditMode}
                required={!isEditMode}
                error={!isEditMode && isInvalidForm && formState.selectedCategory === ''}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {allCategories.map((category) => (
                  <MenuItem key={category.id}
                    value={category.primaryCategoryName}>{category.primaryCategoryName}
                  </MenuItem>
                ))}
              </Select>
              {!isEditMode && isInvalidForm && formState.selectedCategory === '' && <FormHelperText error>{errorMessage}</FormHelperText>}
            </FormControl>
          </Grid>
          <Grid item
            sm={12}
            md={6}>
            <FormControl fullWidth>
              <TextField
                label={t('itemNumber')}
                value={formState.itemNumber}
                onChange={(e: { target: { value: string | number; }; }) => updateFormFieldAndMarkAsDirty('itemNumber', e.target.value)}
                disabled={isEditMode}
                variant="outlined"
                fullWidth
                required
                error={isInvalidFormat || isExistingFormat || (isInvalidForm && formState.itemNumber === '')}
                helperText={getInvalidItemNumberMessage()}
              />
            </FormControl>
          </Grid>
          {formState.selectedEntityType === 0 && <Grid item
            sm={12}
            md={6}>
            <FormControl fullWidth>
              <TextField
                type="number"
                label={t('unitCostPrice')}
                value={formState.unitCostPrice}
                onChange={(e: { target: { value: string | number; }; }) => updateFormFieldAndMarkAsDirty('unitCostPrice', e.target.value)}
                variant="outlined"
                fullWidth
                required
                error={isInvalidForm && (formState.unitCostPrice === 0 || String(formState.unitCostPrice) === '')}
                helperText={isInvalidForm && (formState.unitCostPrice === 0 || String(formState.unitCostPrice) === '') ? errorMessage : ''}
              />
            </FormControl>
          </Grid>
          }
          {formState.selectedEntityType === 0 && <Grid item
            sm={12}
            md={6}>
            <FormControl fullWidth>
              <TextField
                type="number"
                value={formState.weight}
                label={t('weight')}
                onChange={(e) => updateFormFieldAndMarkAsDirty('weight', e.target.value)}
                variant="outlined"
                fullWidth
                required
                error={isInvalidForm && (formState.weight === 0 || String(formState.weight) === '')}
                helperText={isInvalidForm && (formState.weight === 0 || String(formState.weight) === '') ? errorMessage : ''}
              />
            </FormControl>
          </Grid>
          }
          {formState.selectedEntityType === 0 && <Grid item
            sm={12}
            md={6}
            alignSelf='end'>
            <FormControl fullWidth
              size="small">
              <InputLabel id="primaryUom-label">{t('primaryUom')}</InputLabel>
              <Select
                labelId='primaryUom-label'
                value={formState.primaryUOM}
                label={t('primaryUom')}
                onChange={(e) => updateFormFieldAndMarkAsDirty('primaryUOM', e.target.value)}
                required
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {primaryUoms.map((unit) => (
                  <MenuItem key={unit}
                    value={unit}>{unit}
                  </MenuItem>
                ))}
              </Select>
              {isInvalidForm && formState.primaryUOM === '' && <FormHelperText error>{errorMessage}</FormHelperText>}
            </FormControl>
          </Grid>
          }
          {formState.selectedEntityType === 0 && <Grid item
            sm={12}
            md={6}>
            <FormControl fullWidth>
              <TextField
                type="number"
                label={t('uomConversion')}
                value={formState.uomConversion}
                onChange={(e: { target: { value: string; }; }) => updateFormFieldAndMarkAsDirty('uomConversion', e.target.value)}
                fullWidth
                required
                error={isInvalidForm && (formState.uomConversion === 0 || String(formState.uomConversion) === '')}
                helperText={isInvalidForm && (formState.uomConversion === 0 || String(formState.uomConversion) === '') ? errorMessage : ''}
              />
            </FormControl>
          </Grid>
          }
          {!isEditMode && <Grid item
            sm={6}
            xs={12}>
            <FormControlLabel
              control={<Checkbox
                checked={formState.isAllStores}
                onChange={handleSelectAllStoresChange} />}
              label='All Stores'
            />
          </Grid>
          }
          {!isEditMode && <Grid item
            xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                multiple
                disabled={formState.isAllStores}
                limitTags={2}
                options={user?.stores ?? []}
                value={formState.selectedStores}
                onChange={onStoreChange}
                getOptionKey={(option) => option.storeNumber ?? ''}
                getOptionLabel={(option) => option.storeName ?? ''}
                renderInput={(params) => (
                  <TextField {...params}
                    label={t('selectStores')}
                    placeholder={t('selectStores')}
                  />
                )}
              />
              {isInvalidForm && formState.selectedStores?.length === 0 && <FormHelperText error>{errorMessage}</FormHelperText>}
            </FormControl>
          </Grid>
          }
          {formState.selectedEntityType !== 0 && (
            <Grid item
              xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  id='selected-recipe-category'
                  fullWidth
                  multiple={false}
                  clearOnBlur
                  clearIcon={<SearchSm />}
                  options={stockItems}
                  value={selectedStockItem}
                  onChange={onStockItemChange}
                  getOptionKey={(option) => option.id ?? ''}
                  getOptionLabel={(option) => (option && `${option.itemNumber} - ${option.description}`) ?? ''}
                  disabled={stockItems.length === 0}
                  renderInput={(params) => (
                    <TextField {...params}
                      label={t('searchForItem')}
                    />
                  )}
                />
                {isInvalidForm && formState.recipeComponents?.length === 0 && <FormHelperText error>{errorMessage}</FormHelperText>}
              </FormControl>
            </Grid>
          )}
          {formState.selectedEntityType !== 0 && formState.recipeComponents.length > 0 && (
            <>
              <Grid
                container
                item
                xs={12}
                display='flex'
                flex={1}
                spacing={6}
                flexDirection='row'
              >
                <Grid item
                  flex={1}>
                  <Typography variant='textSM'
                    fontWeight="bold"
                    textAlign='left'
                  >{t('item')}</Typography>
                </Grid>
                <Grid item
                  flex={2.5}>
                  <Typography variant='textSM'
                    fontWeight="bold"
                    textAlign='left'
                  >{t('description')}</Typography>
                </Grid>
                <Grid item
                  flex={1}>
                  <Typography variant='textSM'
                    fontWeight="bold"
                    textAlign='left'
                  >{t('weight')}</Typography>
                </Grid>
                <Grid item
                  flex={1}>
                  <Typography variant='textSM'
                    fontWeight="bold"
                    textAlign='left'
                  >{t('quantity')}</Typography>
                </Grid>
                <Grid item
                  flex={1}>
                  <Typography variant='textSM'
                    fontWeight="bold"
                    textAlign='left'
                  >{t('volume')}</Typography>
                </Grid>
                <Grid item
                  flex={0.5}>
                  <Typography variant='textSM'
                    fontWeight="bold"
                    visibility='hidden'
                  >{t('action')}</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                display='flex'
                flexDirection='column'
                xs={12}
                px={6}>
                {formState.recipeComponents.map((component: RecipeComponent, index: number) => {
                  return <RecipeComponentRow
                    key={`${component.componentId} - ${index}`}
                    item={component}
                    index={index}
                    handleRecipeComponentChanges={handleRecipeComponentChanges}
                    onRemoveComponent={handleRemoveComponent}
                    isFormValid={!isInvalidForm} />;
                })}
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="secondary"
          onClick={onCancel}
          autoFocus
        >
          {t('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={onSaveRecipe}
          disabled={isSaveButtonDisabled()}
          autoFocus
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateRecipeDialog;
