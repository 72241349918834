import { useContext } from 'react';
import { PurchaseOrderDetailsNoteModal } from './modals/PurchaseOrderDetailsNoteModal';
import { PurchaseOrderDetailsStockCheckModal } from './modals/PurchaseOrderDetailsStockCheckModal';
import MessagesSnackbar from '../../../components/shared/MessageSnackbar';
import { PurchaseOrderDetailsContext, PurchaseOrderDetailsValues } from '../contexts/PurchaseOrderDetailsContext';
import { PurchaseOrderDetailsConfirmModal } from './modals/PurchaseOrderDetailsConfirmModal';
import { PurchaseOrderDetailsCopyConfirmModal } from './modals/PurchaseOrderDetailsCopyConfirmModal';
import { PurchaseOrderDetailsCreditRequestModal } from './modals/PurchaseOrderDetailsCreditRequestModal';
import { PurchaseOrderDetailCreditRequestCutOffModal } from './modals/PurchaseOrderDetailCreditRequestCutOffModal';
import { PurchaseOrderDetailsAddItemsModal } from './modals/PurchaseOrderDetailsAddItemsModal';
import { useTranslation } from 'react-i18next';

const PurchaseOrdersDetailsPopUps = () => {
  const { closeSnackBar,
    snackBarDuration,
    isSnackBarOpen,
    snackbarMessage,
    snackBarSeverity,showOverReceivingWarningModal, closeOverReceivingWarning } = useContext(PurchaseOrderDetailsContext) as PurchaseOrderDetailsValues;
  const { t } = useTranslation('purchaseOrderDetails');

  return <>
    <PurchaseOrderDetailsNoteModal />
    <PurchaseOrderDetailsStockCheckModal />
    <PurchaseOrderDetailsCopyConfirmModal />
    <PurchaseOrderDetailsConfirmModal />
    <PurchaseOrderDetailsCreditRequestModal />
    <PurchaseOrderDetailCreditRequestCutOffModal />
    <PurchaseOrderDetailsAddItemsModal />
    <MessagesSnackbar onClose={closeSnackBar}
      open={isSnackBarOpen}
      severity={snackBarSeverity}
      message={snackbarMessage}
      duration={snackBarDuration} />
    <MessagesSnackbar onClose={closeOverReceivingWarning}
      open={showOverReceivingWarningModal}
      severity={'warning'}
      message={t('overReceivingWarnings.warningBody')}
      duration={null} />
  </>;
  
};

export default PurchaseOrdersDetailsPopUps;