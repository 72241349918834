/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Building02 } from '../../../assets';
import { Store, User } from '../../../app/services/api/generated';
import { UserContext } from '../../../components/shared/useUser';
import useNswagClient from '../../../hooks/api/useNswagClient';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../theme/context/themeProviderContext';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import useLogError from '../../../hooks/useLogError';
import useLocalStorage from '../../../hooks/useLocalStorage';
import LoadingWheel from '../../../components/ui/LoadingWheel';

const textClamp = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
};

const InitialStoreSelector = () => {
  const { getStores, getCurrentUser, getBrandingForFranchise } = useNswagClient();
  const [storedValue, setValue] = useLocalStorage('token', null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [brandingTheme, changeBranding] = useContext(ThemeContext);
  const navigate = useNavigate();
  const { t } = useTranslation('authentication');
  const { setUserContext, setSelectedStore, isAdmin, user }: any = useContext(UserContext);
  const { logError } = useLogError();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isAdmin()) {
      retrieveStores();
    } else {
      retrieveUser();
    }
  }, []);

  const handleSingleStoreUser = (stores: Store[]) => {
    if (stores.length === 1) {
      setSelectedStore(stores[0]);
      navigate('/');
      updateBranding(stores[0].franchiseId ?? 0);
    }
  };

  const retrieveUser = () => {
    setLoading(true);
    getCurrentUser().then((result) => {
      if (result?.data?.stores) {
        const user: User = {
          ...result.data,
          stores: result.data.stores,
        };
        setUserContext(user, storedValue);
        handleSingleStoreUser(result.data.stores);
      } else {
        setErrorMessage(t('errorMessages.noStoresAssigned'));
        localStorage.clear();
      }
    })
      .catch((error) => {
        localStorage.clear();
        logError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const retrieveStores = () => {
    setLoading(true);
    getStores().then((res) => {
      if (res?.data?.stores) {
        const updatedUser: User = { ...user, stores: res.data.stores, userLevel: 'Admin' };
        setUserContext(updatedUser, storedValue);
        handleSingleStoreUser(res.data.stores);
      }
    }).catch((error) => {
      if (error.status === 403)
        setErrorMessage(t('errorMessages.noStoresAssigned'));
      logError(error);
    })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeStore = (newValue: Store) => {
    if (newValue) {
      setSelectedStore(newValue);
      updateBranding(newValue.franchiseId ?? 0);
    }
  };

  const updateBranding = ((id: number) => {
    getBrandingForFranchise(id)
      .then((result) => {
        if (result?.data) {
          changeBranding(result.data);
          navigate('/');
          window.location.reload();
        }

        if (!result?.data) {
          changeBranding(null);
          navigate('/');
        }
      })
      .catch((error) => {
        console.log(error);
        changeBranding(null);
        navigate('/');
      });
  });

  return (
    <>
      {loading ? (
        <LoadingWheel />
      ) : (
        <>
          {errorMessage &&
        <Box
          sx={{ position: 'absolute', top: '35%' }}>
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
          }
          {!errorMessage && (
            <Box sx={{
              position: 'absolute',
              top: '35%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '360px',
            }}>
              <Typography
                variant="textSM"
                sx={{
                  textAlign: 'left',
                }}
              >
                {t('selectStore')}
              </Typography>
              <Autocomplete
                onChange={(event: any, newValue: Store | null) => {
                  if (newValue)
                    handleChangeStore(newValue);
                }}
                id="select-store"
                options={user?.stores ?? []}
                sx={{ width: '100%', overflow: 'auto', pt: 4 }}
                getOptionLabel={(option: Store) => option.storeName ?? ''}
                filterOptions={(options: Store[], state) => options.filter((option) => option.storeNumber?.startsWith(state.inputValue) || option.storeName?.toLowerCase().includes(state.inputValue.toLowerCase()))}
                renderOption={(props, option) => (
                  <Box component="li"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 }, gap: 2 }}
                    {...props}>
                    <Building02 style={{ minWidth: '24px' }} />
                    <Typography
                      variant='textSM'
                      sx={{
                        ...textClamp,
                      }}>
                      {option.storeName}
                    </Typography>
                  </Box>
                )}
                renderInput={(params) => {
                  return <TextField placeholder={t('selectStore')}
                    {...params as TextFieldProps} />;
                }} />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default InitialStoreSelector;
